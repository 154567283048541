import React from 'react';

const Modal = ({
  filteredUsers,
  selectedUsers,
  toggleUserSelection,
  closeModal,
  sendEmails,
}) => {
  return (
    <div className="modal-overlay">
      <div className="modal">
        <h3>Select Users</h3>
        <ul>
          {filteredUsers.map((user) => (
            <li key={user.id}>
              <label>
                {user.email}
                <input
                  type="checkbox"
                  checked={selectedUsers.some((u) => u.id === user.id)}
                  onChange={() => toggleUserSelection(user)}
                />
              </label>
            </li>
          ))}
        </ul>
        <button onClick={sendEmails} disabled={selectedUsers.length === 0}>
          Send Emails
        </button>
        <button onClick={closeModal}>Cancel</button>
      </div>
    </div>
  );
};

export default Modal;
