import React from 'react';

const UserList = ({
  filteredUsers,
  missingDataUsers,
  filterField,
  selectedUsers,
  toggleUserSelection,
  isCollapsed,
  setIsCollapsed,
  isLoading,
}) => {
  const truncateText = (text, maxLength = 30) => {
    if (text && text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  };

  const renderFields = (user) => {
    return Object.keys(user).map((key) => (
      <p key={key}>
        <strong>{key}:</strong>{' '}
        {key === 'timestamp' || key.toLowerCase().includes('date')
          ? user.formattedTimestamp || 'N/A'
          : JSON.stringify(user[key], null, 2) || 'N/A'}
      </p>
    ));
  };

  return (
    <div className="user-list">
      <h2>Filtered Users</h2>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <ul>
          {filteredUsers.map((user) => (
            <li key={user.id}>
              <label>
                <strong>{truncateText(user.name || user.email || user.id || 'Unnamed User')}</strong>
                <input
                  type="checkbox"
                  checked={selectedUsers.some((u) => u.id === user.id)}
                  onChange={() => toggleUserSelection(user)}
                />
              </label>
              <hr />
              {filterField.value === 'all'
                ? renderFields(user)
                : <p>{truncateText(user[filterField.value] || 'N/A')}</p>}
            </li>
          ))}
        </ul>
      )}
      {missingDataUsers.length > 0 && (
        <div className="missing-data">
          <button onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ? 'Show Users Without Data' : 'Hide Users Without Data'}
          </button>
          {!isCollapsed && (
            <ul>
              {missingDataUsers.map((user) => (
                <li key={user.id}>
                  <strong>{truncateText(user.name || user.email || user.id || 'Unnamed User')}</strong>
                  <p>Missing: {filterField.label}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default UserList;
