import React, { useState, useEffect } from 'react';
import { db, collection, getDocs } from '../../../firebaseConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown, faEquals, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import './WaitlistUser.css'; // Custom CSS for WaitlistUser
import { useNavigate } from "react-router-dom";

const WaitlistUser = ({  dateOption, preciseDate, startDate, endDate, userType }) => {  const [isLoading, setIsLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0);
  const [usersInRange, setUsersInRange] = useState(0);
  const [previousUsersInRange, setPreviousUsersInRange] = useState(0);
  const [growthPercentage, setGrowthPercentage] = useState(0);
  const navigate = useNavigate();

 
  const navigateToWaitlist = () => {
    navigate('/dashboard-admin/waitlist-management'); // Navigate to the waitlist management page
  };
  useEffect(() => {
    fetchWaitlistData();
  }, [dateOption, preciseDate, startDate, endDate, userType]);

  const fetchWaitlistData = async () => {
    setIsLoading(true);
    try {
      const waitlistCollectionRef = collection(db, 'waitlist');
      const snapshot = await getDocs(waitlistCollectionRef);

      const usersData = [];
      snapshot.forEach((doc) => {
        usersData.push({ id: doc.id, ...doc.data() });
      });

      const totalUsersCount = usersData.length;
      const usersInRangeCount = calculateUsersInRange(usersData);
      const previousUsersInRangeCount = await calculatePreviousUsersInRange(usersData);

      setTotalUsers(totalUsersCount);
      setUsersInRange(usersInRangeCount);
      setPreviousUsersInRange(previousUsersInRangeCount);
      setGrowthPercentage(calculateGrowthPercentage(usersInRangeCount, previousUsersInRangeCount));
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching waitlist data:', error);
      setIsLoading(false);
    }
  };

  const calculateUsersInRange = (usersData) => {
    if (dateOption === 'precise') {
      const preciseDateStart = new Date(preciseDate);
      preciseDateStart.setHours(0, 0, 0, 0);
      const preciseDateEnd = new Date(preciseDate);
      preciseDateEnd.setHours(23, 59, 59, 999);
      return usersData.filter((user) => {
        const userDate = new Date(user.timestamp.toDate());
        return userDate >= preciseDateStart && userDate <= preciseDateEnd;
      }).length;
    } else if (startDate && endDate) {
      return usersData.filter((user) => {
        const userDate = new Date(user.timestamp.toDate());
        return userDate >= new Date(startDate) && userDate <= new Date(endDate);
      }).length;
    }
    return usersData.length;
  };

  const calculatePreviousUsersInRange = async (usersData) => {
    if (dateOption === 'all') {
      return 0;
    }

    let previousStartDate = new Date(startDate);
    let previousEndDate = new Date(endDate);

    switch (dateOption) {
      case 'today':
        previousStartDate.setDate(startDate.getDate() - 1);
        previousEndDate.setDate(endDate.getDate() - 1);
        break;
      case 'last7days':
        previousStartDate.setDate(startDate.getDate() - 7);
        previousEndDate.setDate(endDate.getDate() - 7);
        break;
      case 'last30days':
        previousStartDate.setDate(startDate.getDate() - 30);
        previousEndDate.setDate(endDate.getDate() - 30);
        break;
      case 'range':
      case 'lastYear':
        previousStartDate.setFullYear(startDate.getFullYear() - 1);
        previousEndDate.setFullYear(endDate.getFullYear() - 1);
        break;
      default:
        return 0;
    }

    return usersData.filter((user) => {
      const userDate = new Date(user.timestamp.toDate());
      return userDate >= previousStartDate && userDate <= previousEndDate;
    }).length;
  };

  const calculateGrowthPercentage = (currentCount, previousCount) => {
    if (previousCount === 0) return currentCount > 0 ? 100 : 0;
    return (((currentCount - previousCount) / previousCount) * 100).toFixed(2);
  };

  const getGrowthClass = (growthPercentage) => {
    if (growthPercentage > 0) {
      return 'waitlist-growth-positive';
    } else if (growthPercentage < 0) {
      return 'waitlist-growth-negative';
    } else {
      return 'waitlist-growth-null';
    }
  };

  const getGrowthIcon = (growthPercentage) => {
    if (growthPercentage > 0) {
      return faAngleUp;
    } else if (growthPercentage < 0) {
      return faAngleDown;
    } else {
      return faEquals;
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="waitlist-container" onClick={navigateToWaitlist}>
      <div className="waitlist-box">
        <div className="waitlist-summary">
          <p className="waitlist-summary-title">
            <FontAwesomeIcon icon={faUserGroup} /> Waitlist Users
          </p>
          <div className="waitlist-summary-count">
            <p className="waitlist-count">{totalUsers}</p>
            <p className={`waitlist-growth ${getGrowthClass(growthPercentage)}`}>
              {growthPercentage}% <FontAwesomeIcon icon={getGrowthIcon(growthPercentage)} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitlistUser;
