import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { db, doc, getDoc, setDoc } from "../../../firebaseConfig";
import "./ConnectAdmin.css";

const ConnectAdmin = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const processLogin = async (walletId, walletType) => {
    try {
      const walletRef = doc(db, "wallets", walletId);
      const walletSnap = await getDoc(walletRef);
      const timestamp = new Date().toISOString();
  
      if (!walletSnap.exists()) {
        await setDoc(walletRef, { walletId, lastLogin: timestamp, walletType });
        console.log("Wallet ID saved to Firestore:", walletId);
      } else {
        console.log("Wallet data retrieved:", walletSnap.data());
      }
  
      const userDocRef = doc(db, "users", walletId);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const profile = userDoc.data().profile;
        if (profile && profile.role === "admin") {
          console.log("Admin role verified:", profile.username);
          navigate(`/dashboard-admin/?walletId=${walletId}`);
        } else {
          console.error(`The wallet ID ${walletId} isn't registered as an admin.`);
          setErrorMessage(`The wallet ID ${walletId} isn't registered as an admin.`);
        }
      } else {
        console.error(`No user found with wallet ID ${walletId}.`);
        setErrorMessage(`No user found with wallet ID ${walletId}.`);
      }
    } catch (error) {
      console.error("Error during login processing:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
    }
  };
  

  const handleLoginWithMetamask = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        const account = accounts[0];
        const message = "Please sign this message to confirm your identity.";
        const signature = await window.ethereum.request({
          method: "personal_sign",
          params: [message, account],
        });

        console.log("Signature from MetaMask:", signature);
        await processLogin(account, "Ethereum");
      } catch (error) {
        console.error("Error with MetaMask login:", error);
        setErrorMessage("MetaMask authentication failed. Please try again.");
      }
    } else {
      setErrorMessage("MetaMask is not installed. Install it and try again.");
    }
  };

  const handleLoginWithPhantom = async () => {
    if ("solana" in window && window.solana.isPhantom) {
      try {
        const response = await window.solana.connect();
        const publicKey = response.publicKey.toString();
        const message = new TextEncoder().encode("Please sign this message to confirm your identity.");
        const signedMessage = await window.solana.signMessage(message);

        console.log("Signed message from Phantom:", signedMessage.signature.toString());
        await processLogin(publicKey, "Solana");
      } catch (error) {
        console.error("Error connecting to Phantom:", error);
        setErrorMessage("Phantom authentication failed. Please try again.");
      }
    } else {
      setErrorMessage("Phantom wallet is not available. Please install Phantom and try again.");
    }
  };

  return (
    <div className="admin-connect">
      <img
        className="admin-connect-image"
        src="https://firebasestorage.googleapis.com/v0/b/third--space.appspot.com/o/ImageWebSite%2F3s-logo.png?alt=media&token=8a69bcce-2e9f-463e-8cba-f4c2fec1a904"
        alt="thirdspace logo"
      />
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <div className="wallet-list">
        <button className="admin-connect-button" onClick={handleLoginWithPhantom}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/third--space.appspot.com/o/ImageWebSite%2FPopup%2Fphantom-logo.png?alt=media&token=5ffe611b-3ccd-4663-81e4-59feeb1dbba7"
            alt="Phantom"
          />
          Phantom
        </button>
        <button className="admin-connect-button" onClick={handleLoginWithMetamask}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/third--space.appspot.com/o/ImageWebSite%2FPopup%2Fmetamask-logo.png?alt=media&token=507097be-0cc4-4d93-a87b-99c67d82cfe5"
            alt="MetaMask"
          />
          MetaMask
        </button>
      </div>
    </div>
  );
};

export default ConnectAdmin;
