import React, { useState } from 'react';

const AnalyticsPanel = ({
  filteredUsers,
  selectedUsers,
  sendEmails,
  setIsExportModalOpen,
  isLoading,
  emailStatus,
  setEmailStatus
}) => {

  return (
    <div className="analytics-panel">
      <div className="analytics-box">
        <h3>Total Users</h3>
        <p>{filteredUsers.length}</p>
      </div>
      <div className="analytics-box">
        <h3>Send Referral Emails</h3>
        <button
          onClick={() => (selectedUsers.length > 0 ? sendEmails() : setEmailStatus('Error: No Users Selected'))}
          disabled={isLoading}
        >
          {selectedUsers.length > 0
            ? `Send Emails to ${selectedUsers.length} Users`
            : 'No Users Selected'}
        </button>
        <p style={{ fontSize:"16px", marginTop: '10px', color: emailStatus.startsWith('Error') ? 'red' : 'green' }}>
          {emailStatus}
        </p>
      </div>
      <div className="analytics-box">
        <h3>Export Data</h3>
        <button onClick={() => setIsExportModalOpen(true)}>Export Data</button>
      </div>
      <div className="analytics-box">
        <h3>Newsletter to come...</h3>
        <button >Newsletter to come...</button>
      </div>
    </div>
  );
};

export default AnalyticsPanel;
