import React from 'react';

const FilterDropdown = ({ filterField, setFilterField, filterOptions, selectedDatabase, setSelectedDatabase, databaseOptions }) => {
    return (
        <div className="filters">
            {/* Database Selector */}
            <div className="filter-dropdown database-selector">
                <select
                    value={selectedDatabase}
                    onChange={(e) => setSelectedDatabase(e.target.value)}
                >
                    {databaseOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            <div className="filter-dropdown">
                <select
                    value={filterField.value}
                    onChange={(e) =>
                        setFilterField(filterOptions.find((option) => option.value === e.target.value))
                    }
                >
                    {filterOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default FilterDropdown;
