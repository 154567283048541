import React, { useState, useEffect } from 'react';

const ExportModal = ({ filteredUsers, closeModal, selectedDatabase }) => {
  const [selectedFields, setSelectedFields] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);
  const [format, setFormat] = useState('csv');

  useEffect(() => {
    if (filteredUsers.length > 0) {
      const fields = Object.keys(filteredUsers[0]);
      setAvailableFields(fields);
      setSelectedFields(fields); // Default: select all fields
    }
  }, [filteredUsers]);

  const handleFieldChange = (field) => {
    setSelectedFields((prevFields) =>
      prevFields.includes(field)
        ? prevFields.filter((f) => f !== field)
        : [...prevFields, field]
    );
  };

  const exportData = () => {
    const exportData = filteredUsers.map((user) => {
      const filteredUser = {};
      selectedFields.forEach((field) => {
        filteredUser[field] = user[field] || 'N/A';
      });
      return filteredUser;
    });

    if (format === 'csv') {
      const headers = selectedFields.join(',');
      const csvRows = [
        headers,
        ...exportData.map((user) =>
          selectedFields.map((field) => user[field]).join(',')
        ),
      ];
      const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${selectedDatabase}_data.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (format === 'json') {
      const blob = new Blob([JSON.stringify(exportData, null, 2)], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${selectedDatabase}_data.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    closeModal();
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h3>Export Data for {selectedDatabase}</h3>
        <div>
          <h4>Select Fields</h4>
          {availableFields.length > 0 ? (
            availableFields.map((field) => (
              <label key={field} style={{ display: 'block' }}>
                <input
                  type="checkbox"
                  checked={selectedFields.includes(field)}
                  onChange={() => handleFieldChange(field)}
                />
                {field}
              </label>
            ))
          ) : (
            <p>No fields available for export.</p>
          )}
        </div>
        <div>
          <h4>Select Format</h4>
          <label>
            <input
              type="radio"
              name="format"
              value="csv"
              checked={format === 'csv'}
              onChange={() => setFormat('csv')}
            />
            CSV
          </label>
          <label>
            <input
              type="radio"
              name="format"
              value="json"
              checked={format === 'json'}
              onChange={() => setFormat('json')}
            />
            JSON
          </label>
        </div>
        <div>
          <button onClick={exportData} disabled={selectedFields.length === 0}>
            Export
          </button>
          <button onClick={closeModal}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ExportModal;
