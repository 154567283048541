import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import { db } from '../../../firebaseConfig';
import FilterDropdown from '../Waitlist/FilterDropdown';
import UserList from '../Waitlist/UserList';
import AnalyticsPanel from '../Waitlist/AnalyticsPanel';
import Modal from '../Waitlist/Modal';
import ExportModal from '../Waitlist/ExportModal';
import './WaitlistManagement.css';
import { updateTimestampIfMissing, formatTimestampSync } from '../Waitlist/formatAndUpdateTimestamp';
import { useNavigate } from 'react-router-dom';


const databaseOptions = [
  { value: 'waitlist', label: 'Waitlist' },
  { value: 'users', label: 'Users' },
  { value: 'wallets', label: 'Wallets' },
];

const WaitlistManagement = () => {
  const [selectedDatabase, setSelectedDatabase] = useState('waitlist');
  const [waitlistUsers, setWaitlistUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [missingDataUsers, setMissingDataUsers] = useState([]);
  const [filterField, setFilterField] = useState({ value: 'all', label: 'All Fields' });
  const [filterOptions, setFilterOptions] = useState([{ value: 'all', label: 'All Fields' }]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [emailStatus, setEmailStatus] = useState(''); // New state to track status


  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const querySnapshot = await getDocs(collection(db, selectedDatabase));
        const users = querySnapshot.docs.map((doc) => {
          const user = { id: doc.id, ...doc.data() };
  
          // Format timestamp synchronously
          user.formattedTimestamp = formatTimestampSync(user.timestamp);
  
          // Update Firestore if timestamp is missing
          updateTimestampIfMissing(user, selectedDatabase);
  
          return user;
        });
  
        setWaitlistUsers(users);
        setFilteredUsers(users);
  
        // Generate filter options dynamically
        if (users.length > 0) {
          const fields = Object.keys(users[0]);
          setFilterOptions([
            { value: 'all', label: 'All Fields' },
            ...fields.map((field) => ({ value: field, label: field })),
          ]);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchUsers();
  }, [selectedDatabase]);
  



useEffect(() => {
  filterUsers();
}, [filterField, waitlistUsers]);

const filterUsers = () => {
  let filtered = [...waitlistUsers];
  let missing = [];

  if (filterField.value !== 'all') {
    filtered = filtered.filter((user) => user[filterField.value]);
    missing = waitlistUsers.filter((user) => !user[filterField.value]);
  }

  setFilteredUsers(filtered);
  setMissingDataUsers(missing);
};
const updateUsers = (updatedUsers) => {
  setFilteredUsers(updatedUsers);
};

const sendEmails = async () => {
  if (selectedUsers.length === 0) {
    setEmailStatus('Error: Please select at least one user to send emails.');
    return;
  }

  setEmailStatus('Compiling...');

  try {
    const results = await Promise.all(
      selectedUsers.map(async (user) => {
        const response = await fetch(
          'https://us-central1-third--space.cloudfunctions.net/assignReferralCodeForUser',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: user.email }),
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to send email to ${user.email}`);
        }

        const result = await response.json();
        console.log(`Referral email sent to ${user.email}:`, result);
        return result;
      })
    );

    setEmailStatus(`${results.length} emails sent successfully!`);
    setSelectedUsers([]); // Clear selected users after successful sending
  } catch (error) {
    console.error('Error sending emails:', error);
    setEmailStatus(`Error: ${error.message}`);
  }
};


return (
  <div className="waitlist-management">
    <div className='waitlist-management-header'>

    <button className='demo-button' onClick={() => navigate("../dashboard-admin")}> Dashboard Admin</button>
    <h1>Waitlist Management</h1>

    </div>

    

    <FilterDropdown
      filterField={filterField}
      setFilterField={setFilterField}
      filterOptions={filterOptions}
      selectedDatabase={selectedDatabase}
      setSelectedDatabase={setSelectedDatabase}
      databaseOptions={databaseOptions}
    />

    <div className="management-container">
      <UserList
        filteredUsers={filteredUsers}
        missingDataUsers={missingDataUsers}
        filterField={filterField}
        selectedUsers={selectedUsers}
        toggleUserSelection={(user) =>
          setSelectedUsers((prevSelected) =>
            prevSelected.some((u) => u.id === user.id)
              ? prevSelected.filter((u) => u.id !== user.id)
              : [...prevSelected, user]
          )
        }
        isLoading={isLoading}
        selectedDatabase={selectedDatabase} // Pass the selected database
      />


      <AnalyticsPanel
        filteredUsers={filteredUsers}
        selectedUsers={selectedUsers}
        setIsModalOpen={setIsModalOpen}
        setIsExportModalOpen={setIsExportModalOpen}
        isLoading={isLoading}
        sendEmails={sendEmails}
        emailStatus={emailStatus}
        setEmailStatus={setEmailStatus}
      />
    </div>

    {isModalOpen && (
      <Modal
        filteredUsers={filteredUsers}
        selectedUsers={selectedUsers}
        closeModal={() => setIsModalOpen(false)}
      />
    )}

    {isExportModalOpen && (
      <ExportModal
        filteredUsers={filteredUsers}
        closeModal={() => setIsExportModalOpen(false)}
        selectedDatabase={selectedDatabase}
      />
    )}
  </div>
);
};

export default WaitlistManagement;
