// src/App.js
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from 'react';

import ReactGA from 'react-ga';
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Resources from "./pages/Resources"; // Corrected to Resources
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import Terms from "./pages/legal/Terms";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import GetStartedMobile from "./components/website/GetStartedMobile";
import Agency from "./pages/Agency";
import Builder from "./pages/Builder";
import Academy from "./pages/Academy";
import About from "./pages/About";
import WaitlistPage from "./pages/WaitlistPage";
import ConnectAdmin from './components/dashboard/Admin/ConnectAdmin';
import DashboardAdmin from './components/dashboard/Admin/DashboardAdmin';
import WaitlistManagement from "./components/dashboard/Admin/WaitlistManagement";
export default function App() {
  const [isAdminSubdomain, setIsAdminSubdomain] = useState(false);
  const [walletId, setWalletId] = useState(localStorage.getItem("userAccount"));

  useEffect(() => {
    const subdomain = window.location.hostname.split('.')[0];
    if (subdomain === 'admin') {
      setIsAdminSubdomain(true);
    }
  }, []);

  return (
    <div>
      <Router>
              {isAdminSubdomain ? (
                <Routes>
                  <Route path="/" element={<ConnectAdmin walletId={walletId} />} />
                  <Route path="/dashboard-admin" element={<DashboardAdmin />} />
                  <Route path="/dashboard-admin/waitlist-management" element={<WaitlistManagement />} />
                  <Route path="*" element={<NoPage />} />
                </Routes>
              ) : (
                <Routes>
                  <Route index element={<Home />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route path="/resources" element={<Resources />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/waitlist" element={<WaitlistPage/>}/>
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/get-started-mobile" element={<GetStartedMobile />} />
                  <Route path="/3s-agency" element={<Agency />} />
                  <Route path="/3s-builder" element={<Builder />} />
                  <Route path="/3s-academy" element={<Academy />} />
                  <Route path="/about" element={<About />} />
                  <Route path="*" element={<NoPage />} />
                  {/* <Route path="/admin" element={<ConnectAdmin />} />
                  <Route path="/dashboard-admin" element={<DashboardAdmin />} />
                  <Route path="/dashboard-admin/waitlist-management" element={<WaitlistManagement />} /> */}

                </Routes>
              )}
      </Router>
    </div>
  );
}
