import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';

// Synchronous function to format the timestamp
export const formatTimestampSync = (value) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  };

  if (!value) {
    return null; // Indicates that a timestamp is missing
  }

  // Handle Firestore Timestamps
  if (value.seconds && value.nanoseconds) {
    const date = new Date(value.seconds * 1000);
    return date.toLocaleString('fr-FR', options);
  }

  // Handle ISO strings
  if (typeof value === 'string' && Date.parse(value)) {
    const date = new Date(value);
    return date.toLocaleString('fr-FR', options);
  }

  return value; // Return value as-is if not a recognized timestamp
};

// Async function to update Firestore if the timestamp is missing
export const updateTimestampIfMissing = async (user, selectedDatabase) => {
  const currentTimestamp = new Date();
  const formattedTimestamp = currentTimestamp.toLocaleString('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  });

  if (!user.timestamp) {
    try {
      const userDocRef = doc(db, selectedDatabase, user.id);
      await updateDoc(userDocRef, { timestamp: formattedTimestamp });
      console.log(`Added timestamp for user ${user.id}`);
    } catch (error) {
      console.error(`Error updating timestamp for user ${user.id}:`, error);
    }
    return formattedTimestamp;
  }

  return null; // No update needed
};
